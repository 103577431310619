.clickedCountry {
    fill: #002677;
}

svg {
    display: block;
    margin: auto;
}

.country {
    display: inline-block;
    width: 100%;
    height: 40px;
}

.tooltipTitle {
    font-size: 10px;
    text-align: left;
    font-weight: bold;
    color: #243b5f;
    opacity: 1;
    margin-bottom: 3px;
    padding-bottom: 4px;

    display: flex;
    align-items: center;
    justify-content: center;
}

.country:hover {
    fill: #ffc0bf;
}

path {
    cursor: pointer;
    display: block;
}

.tooltipMap {
    visibility: hidden;
    width: 150px;
    height: 90px;
    background-color: #fff;
    padding: 10px 15px 25px 15px;
    position: absolute;
    overflow: initial;
    transform: translate3d(-50%, -50%, 0);
    box-shadow: 0px 0px 3px #0000004d;
    opacity: 1;
}
.tooltipMap::after {
    position: absolute;
    content: '';
    width: 0px;
    height: 0px;
    border-top: 5px solid #ffffff;
    border-right: 5px solid transparent;
    border-bottom: 0px solid transparent;
    border-left: 5px solid transparent;
    top: 99%;
    right: 50%;
}
.tooltipSpan {
    border-top: 5px solid #fff;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
}

.tooltipText {
    font-size: 12px;
    color: #243b5f;
    text-align: center;
}

.textArea {
    margin-bottom: 5px;
    margin-top: 6px;
}

.tooltipImage {
    width: 20px;
    height: 20px;
    opacity: 1;
    border-radius: 20px;
    margin-right: 2px;
    box-shadow: 0px 0px 6px #00000029;
}

.countriesList {
    border-top: 1px solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0;
    margin: 18px -20px -20px -20px;
    padding: 0 18px 8px 18px;
    height: auto;
    max-height: 380px;
    overflow-y: auto;
}

.country {
    display: inline-block;
    width: 100%;
    height: 40px;
}

.countryName {
    color: #283e61;
    position: relative;
    left: 21px;
    bottom: 2px;
}

.apply-button {
    background-color: #00048f;
    color: #fff;
    width: 100px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    margin-left: auto;
    margin-top: 40px;
    margin-bottom: 10px;
}

.countryInput {
    position: absolute;
}

.selectAllSection {
    display: flex;
    height: 26px;
    margin-top: 13px;
    text-align: center;
    color: #283e61;
}

@media (max-width: 1474px) {
    .dropdown-wrapper {
        width: 137px;
    }
}

@media (max-width: 1372px) {
    .dropdown-wrapper {
        width: 102px;
    }
}
